import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/font.css";
import "./assets/css/index.css";
import { SessionUser } from "./lib/SessionUser";
import axios from "axios";

import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { datadogRum } from "@datadog/browser-rum";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Sidebar from "./partials/Sidebar";
import { Mixpanel } from "./lib/Mixpanel";
import { fetchAllRoutes, getPresentRoute } from "./lib/RouteHelper";
import AddFriendModal from "./partials/AddFriendModal";
import UserLoginModal from "./partials/UserLoginModal";
import successImage from "./assets/images/modal-success.png";
import errorImage from "./assets/images/modal-error.png";

datadogRum.init({
  applicationId: "01ccc600-1ab3-41c8-bdd7-9b8c9a6f160e",
  clientToken: "pubab3c534ab79ae12b2ae99ce7b7f34c2f",
  site: "us5.datadoghq.com",
  service: "web_app",
  env: "staging",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const App = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const { route, routeParams } = getPresentRoute(location.pathname);
  const [upcomingEventsCount, setUpcomingEventsCount] = useState(0);

  useEffect(() => {
    const { route, routeParams } = getPresentRoute(location.pathname);
    if (!route) {
      window.location.href = "/";
    }
    Mixpanel.track(`Web::${route.name}::Load`, {
      ...routeParams.params,
      ...queryParams,
    });
    const onScroll = () => {
      const element = document.getElementById("mainContainer");
      const iconElement = document.getElementById("upcomingEventsIconLink");
      if (
        upcomingEventsCount > 0 &&
        window.scrollY > 160 &&
        element.classList.contains("has-upcoming-events") &&
        route.name === "Home"
      ) {
        element.classList.remove("has-upcoming-events");
        if (iconElement) {
          iconElement.classList.remove("close");
          iconElement.classList.add("open");
        }
      }
      if (
        upcomingEventsCount > 0 &&
        window.scrollY < 160 &&
        !element.classList.contains("has-upcoming-events") &&
        route.name === "Home"
      ) {
        element.classList.add("has-upcoming-events");
        if (iconElement) {
          iconElement.classList.remove("open");
          iconElement.classList.add("close");
        }
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [location]);

  useEffect(() => {
    const fetchUserBookings = async () => {
      if (SessionUser.isLoggedIn()) {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
        });
        const url = "/user/bookings.json";
        try {
          const response = (
            await axiosInstance.get(url, {
              headers: { Authorization: `${SessionUser.userToken()}` },
            })
          ).data;
          setUpcomingEventsCount(
            response.data.orders.filter((order) => order.event.title).length
          );
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchUserBookings();
  }, []);

  const containerClassName = () => {
    let className = "fixed-container h-100";
    if (upcomingEventsCount > 0 && route.name === "Home") {
      className = `${className} has-upcoming-events`;
    }
    if (route.name === "Login") {
      className = `${className} no-header`;
    }
    return className;
  };


  return (
    <div className="App h-100">
      <Helmet>
        <html lang="en" />
        <title>Venn - Events happening in your city</title>
        <meta
          name="description"
          content="A daily dispatch of what's happening in Bangalore"
        />
      </Helmet>
      <div className="row d-flex justify-content-center h-100">
        <div id="mainContainer" className={containerClassName()}>
          <Header upcomingEventsCount={upcomingEventsCount} />
          <div className="main-container">
            <div className="main-container-body h-100">
              <Routes>
                {fetchAllRoutes().map((r) => {
                  if (r.path === "/") {
                    return (
                      <Route
                        key={`${r.name}-route`}
                        index
                        element={r.element}
                      />
                    );
                  } else {
                    return (
                      <Route
                        key={`${r.name}-route`}
                        path={r.path}
                        element={r.element}
                      />
                    );
                  }
                })}
              </Routes>
            </div>
          </div>
          <Sidebar />
          <div className="alert-full-screen success-alert" id="successScreen">
            <img src={successImage} style={{ marginBottom: "10px" }} />
            <p id="successScreenText"></p>
          </div>
          <div className="alert-full-screen error-alert" id="errorScreen">
            <img src={errorImage} style={{ marginBottom: "10px" }} />
            <p id="errorScreenText"></p>
          </div>
        </div>
      </div>
      <AddFriendModal />
      <UserLoginModal />
    </div>
  );
};

export default App;
