import accessPass from "../assets/images/access-pass.png";
import CustomButton from "../components/CustomButton";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { SessionUser } from "../lib/SessionUser";

export const AccessPassCheckout = () => {
  const [Razorpay] = useRazorpay();

  // 2. Initialize and open Razorpay payment window
  const initializeRazorpayPayment = (options) => {
    const rzp = new Razorpay(options);
    rzp.on("payment.failed", () => handlePaymentError()); // Register failure event
    rzp.open(); // Open payment modal
  };

  // 1. Razorpay calls the handler function with payment details
  const handlePaymentSuccess = async ({ id, order_id, response }) => {
    //   API CALL
    // Send payment confirmation to your backend
    const paymentConfirmation = {
      payment_id: response.razorpay_payment_id, // Razorpay Payment ID
      order_id: order_id, // Razorpay Order ID
      signature: response.razorpay_signature, // Payment signature for verification
    };

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    try {
      const { data } = await axiosInstance.patch(
        `/user/subscriptions/${id}`,
        paymentConfirmation,
        {
          headers: { Authorization: SessionUser.userToken() },
        }
      );

      if (data.success) {
        document.getElementById("successScreenText").innerHTML =
          "Payment successful! Please Open the app to access Secret Menu.";
        document
          .getElementById("mainContainer")
          .classList.add("success-alert-open");

        setTimeout(() => {
          document.getElementById("successScreenText").innerHTML = "";
          document
            .getElementById("mainContainer")
            .classList.remove("success-alert-open");
          window.location.href = "/";
        }, 3000);
      } else {
        handlePaymentError();
      }
    } catch (error) {
      handlePaymentError();
    }
  };

  const handlePaymentError = () => {
    document.getElementById("errorScreenText").innerHTML =
      "Payment Unsuccessful";
    document.getElementById("mainContainer").classList.add("error-alert-open");
    setTimeout(() => {
      document.getElementById("errorScreenText").innerHTML = "";
      document
        .getElementById("mainContainer")
        .classList.remove("error-alert-open");
    }, 3000);
  };

  const errorHandler = () => {

    console.log("Not Successful");
    
    handlePaymentError();
  };

  const handleClick = async (e) => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    try {
      const { data } = await axiosInstance.post(
        "/user/subscriptions/create_recurring",
        {},
        {
          headers: { Authorization: SessionUser.userToken() },
        }
      );

      if (data) {
        const { subscription } = data.data;

        const options = {
          key: subscription.key,
          subscription_id: subscription.payment.order_id,
          amount: subscription.payment.amount,
          currency: "INR",
          name: "Putting Scene Inc.",
          image: "https://venn.buzz/venn-logo.png",
          handler: async (response) => {
            return handlePaymentSuccess({
              id: subscription.id,
              order_id: subscription.order_id,
              response,
            });
          },
          modal: {
            ondismiss: errorHandler,
          },
          theme: {
            color: "#EDE5D4",
          },
        };

        initializeRazorpayPayment(options);
      }
    } catch (error) {
      console.error("Subscription creation failed:", error);
      if (error.response && error.response.status === 401) {

        const parts = window.location.pathname.split("/").filter(Boolean);
        const lastPath = parts[parts.length - 1];

        window.location.href = "/login?checkout=" + lastPath;
        return;
      }
    }
  };

  return (
    <div
      className="checkout-container"
      style={{
        // width: "99%",
        height: "72vh",
        backgroundColor: "white",
        border: "1px solid black",
        borderRadius: "42px",
        margin: "0.75rem 0.75rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "40px 0",
        overflow: "hidden",
      }}
    >
      <CustomButton
        className="btn primary-btn"
        otherStyle={{
          padding: "16px",
          marginBottom: "16px",
          textTransform: "uppercase",
          fontWeight: "500",
          fontSize: "16px",
          width: "67%",
          borderRadius: "16px",
        }}
        clickEvent={(e) => handleClick(e)}
      >
        Buy Access Pass
      </CustomButton>
      <img
        src={accessPass}
        alt="access-pass"
        style={{
          height: "90%",
          boxShadow: "16px 16px 8px 8px rgba(0, 0, 0, 0.14)",
          borderRadius: "36px",
        }}
      />
    </div>
  );
};
