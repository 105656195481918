import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import OldCard from "../components/OldCard";
import CustomLink from "../components/CustomLink";
import { SessionUser } from "../lib/SessionUser";

// TODO: Need to add loader
function UpcomingExperiences(props) {
  const [showNoData, setShowNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!SessionUser.isLoggedIn()) {
      window.location.href = "/";
    }
    loadData();
  }, []);

  const loadData = async (filter) => {
    setIsLoading(true);
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    const url = "/user/bookings.json";
    try {
      const response = (
        await axiosInstance.get(url, {
          headers: { Authorization: `${SessionUser.userToken()}` },
        })
      ).data;
      setShowNoData(response.data.orders.length === 0);
      setData(response.data.orders);
      setIsLoading(false);
    } catch (err) {
      setShowNoData(true);
      setIsLoading(false);
      console.log(err);
    }
  };

  const renderRows = () => {
    return data
      .filter(order => order.event.title)
      .map((order) => {
        return (
          <OldCard
            showDescription={false}
            event={order.event}
            linkMode="upcoming"
            eventId={order.order.order_id}
          />
        );
      });
  };

  return <div className="upcoming-experiences">{renderRows()}</div>;
}

export default UpcomingExperiences;
