import Home from "../pages/Home";
import Archive from "../pages/Archive";
import Share from "../pages/Share";
import React from "react";
import {matchPath} from "react-router-dom";
import SocialExperience from "../pages/SocialExperience";
import SocialExperienceCheckout from "../pages/SocialExperienceCheckout";
import UpcomingExperiences from "../pages/UpcomingExperiences";
import MySocialExperienceDetails from "../pages/MySocialExperienceDetails";
import Login from "../pages/Login";
import { AccessPassCheckout } from "../pages/AccessPassCheckout";


const routes = [
    { name: 'Login', path: '/login', element: <Login /> },
    { name: 'Home', path: '/', element: <Home /> },
    { name: 'Archive', path: 'archive', element: <Archive/> },
    { name: 'Share', path: 'share/:id', element: <Share/> },
    { name: 'Upcoming Experiences', path: 'my_social_experience', element: <UpcomingExperiences /> },
    { name: 'Upcoming Social Experience Details', path: 'my_social_experience/:order_id', element: <MySocialExperienceDetails /> },
    { name: 'Social Experience Details', path: 'social_experience/:id', element: <SocialExperience/> },
    { name: 'Social Experience Checkout', path: 'social_experience/:id/checkout', element: <SocialExperienceCheckout /> },
    { name: 'Access Pass Checkout', path: 'access_pass_payment', element: <AccessPassCheckout /> },
];

export const fetchAllRoutes = () => {
    return routes
}

export const getPresentRoute = (pathname) => {
    const filteredRoute = routes.filter((route) => matchPath(route.path, pathname) !== null)[0];
    if(filteredRoute) {
        return { route: filteredRoute, routeParams: matchPath(filteredRoute.path, pathname) }
    } else { window.location.href = "/"; }
    return null;
}
