import { useParams } from "react-router-dom";
import OldCard from "../components/OldCard";
import qrCode from "../assets/images/qr-code.png";
import CustomButton from "../components/CustomButton";
import CustomButtonLink from "../components/CustomButtonLink";
import moment from "moment";
import CustomLink from "../components/CustomLink";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../partials/Loader";
import EventNotFound from "../assets/images/event_not_found.png";
import { SessionUser } from "../lib/SessionUser";

// TODO: Need to add loader
function MySocialExperienceDetails(props) {
  const params = useParams();
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isOrderFound, setIsOrderFound] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let id = params.order_id;
    if ([undefined, null, ""].includes(id)) {
      window.location.href = "/my_social_experience";
    }
    try {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      const { data, _status } = await axiosInstance.get(
        `/user/bookings/${id}.json`,
        { headers: { Authorization: SessionUser.userToken() } }
      );
      setOrder(data.data);
      setIsLoading(false);
      setIsOrderFound(true);
    } catch (err) {
      setIsLoading(false);
      setIsOrderFound(false);
    }
  };

  const formatBackendDate = (date) => {
    return moment(date, "YYYY-MM-DDTHH:mm:SS.SSSZ");
  };

  const getCalendarLink = () => {
    const eventDate = formatBackendDate(order.event.event_date);
    const calendarDate =
      eventDate.format("YYYYMMDDTHHmmSSZ") +
      "/" +
      eventDate.add(2, "hours").format("YYYYMMDDTHHmmSSZ");
    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${calendarDate}&text=${order.event.title}&location=${order.event.venue.name}&details=${order.event.short_description}`;
  };

  return (
    <div className="upcoming-event-details">
      {isLoading && (
        <div style={{ margin: "58% 0" }}>
          <Loader />
        </div>
      )}
      {!isLoading && isOrderFound && (
        <>
          <div className="event-card-container">
            <OldCard
              event={order.event}
              showDescription={false}
              linkMode="none"
            />
            <div className="upcoming-event-seperator">
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </div>
            <div className="order-details text-center">
              <span>Name:</span>
              <h3>
                {order.order.user.name}
                {order.order.booked_tickets > 1
                  ? `, +${(order.order.booked_tickets || 3) - 1}`
                  : ``}
              </h3>
            </div>
            <div className="text-center pb-2">
              <img src={order.order.qr_code_url} />
            </div>
            <div className="order-details text-center">
              <span>RSVP ID:</span>
              <h3>{order.order.order_id}</h3>
            </div>
          </div>
          <div className="add-to-calendar-container">
            <CustomLink
              linkId="AddToCalendarButton"
              href={getCalendarLink()}
              className="btn primary-btn"
            >
              Add To Calendar
            </CustomLink>
          </div>
        </>
      )}
      {!isLoading && !isOrderFound && (
        <div
          className="no-events-container"
          style={{ position: "relative", left: "inherit" }}
        >
          <img src={EventNotFound} />
          <p>We couldn’t find the event you’re looking for</p>
        </div>
      )}
    </div>
  );
}

export default MySocialExperienceDetails;
