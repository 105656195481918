// Get user from localStorage with validation
let user = (() => {
    try {
        const storedUser = localStorage.getItem('user');
        if (!storedUser) return {};
        const parsedUser = JSON.parse(storedUser);
        return typeof parsedUser === 'object' ? parsedUser : {};
    } catch (e) {
        console.error('Error parsing user from localStorage:', e);
        return {};
    }
})();

// Validate user is properly logged in with required fields
let isUserLoggedIn = user && typeof user === 'object' && 
    Object.keys(user).length > 0 && 
    user.token && 
    typeof user.token === 'string';

let userActions = {
    userToken: () => {
        // Ensure token exists and is valid
        if (isUserLoggedIn && user.token) {
            return user.token;
        }
        return null;
    },
    
    eventCount: () => {
        // Validate event count is a number
        if (isUserLoggedIn && typeof user.event_count === 'number') {
            return user.event_count;
        }
        return 0;
    },
    
    userProfile: () => {
        // Return full user profile only if logged in
        if (isUserLoggedIn) {
            return {...user}; // Return copy to prevent mutations
        }
        return null;
    },
    
    isLoggedIn: () => {
        return isUserLoggedIn;
    },
    
    logOut: () => {
        localStorage.removeItem('user');
        user = {};
        isUserLoggedIn = false;
    },
    
    updateProfile: (profile) => {
        if (!profile || !profile.user) {
            console.error('Invalid profile data provided');
            return;
        }
        
        // Validate upcoming_events is a number
        const eventCount = typeof profile.upcoming_events === 'number' ? 
            profile.upcoming_events : 0;
            
        const data = {
            ...profile.user,
            event_count: eventCount
        };
        
        try {
            localStorage.setItem('user', JSON.stringify(data));
            user = data;
            isUserLoggedIn = true;
        } catch (e) {
            console.error('Error saving user profile:', e);
        }
    }
};

export let SessionUser = userActions;